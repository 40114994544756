function addDrawControl(map, drawnItems) {
  map.addLayer(drawnItems);
  var drawControl = new L.Control.Draw({
    position: 'bottomleft',
    edit: {
      featureGroup: drawnItems,
      poly: {
        allowIntersection: false
      }
    },
    draw: {
      polyline: false,
      circle: false,
      circlemarker: false,
      marker: false,
      rectangle: false,
      polygon: {
        allowIntersection: false,
        shapeOptions: {
          color: '#00008c',
          weight: 3,
          fillOpacity: 0.25
        }
      },
    }
  });
  map.addControl(drawControl);
  map.on(L.Draw.Event.CREATED, function (event) {
    var layer = event.layer;
    drawnItems.addLayer(layer);
  });
}


function setInitialZoom(map) {
  //const template = templateHelper.getActiveTemplate();
  map.setView([52.56, 6.25], 9);
}


export default {
  addDrawControl,
  setInitialZoom
}
