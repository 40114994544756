<template>
  <aside>
    <b-alert
            :show="dismissCountDown"
            dismissible
            variant="danger"
            @dismissed="dismissCountDown=0"
            fade
    >
      {{ $t('analysis.select_max_4') }}
    </b-alert>
    <div class="analysis-container">
      <div class="row">
        <button id="toggle_precipitation" class="btn" :class="{active: hasPrecipitation}" @click="togglePrecipitation">
          <img
                  src="../../assets/images/precipitation-chart.png"
                  :alt="$t('analysis.add_knmi')"
                  width="32" height="32">
        </button>

        <button id="toggle_prefers_surface_level" v-if="isAdvancedWaterLevel" :class="{active: !prefersSurfaceLevel}" class="btn"  @click="toggleSurfaceLevel()">
        </button>
      </div>
      <div id="analysis_action" class="row" v-if="isAdvancedWaterLevel">
        <analysis-action/>
      </div>
      <div id="search_and_sort_div" class="row">
        <input id="searchBox" class="col-7" type="text" v-model="keyword" :placeholder="$t('analysis.search_series_name')">
        <div class="col-5"><button id="sorting_button" class="btn sort-btn" v-on:click="sort()">{{sortingName}}</button></div>
      </div>
      <div v-if="timeseries">

        <b-form-group v-for="(parameter, index) in shownParameters" :key="index+parameter.name">
          <template slot="label">
            <b-btn :id="parameter.listName+'A'" block v-b-toggle="parameter.listName" @click="loadSeries(parameter.listName)" aria-expanded="true"> {{ parameter.name }} <span class="float-right badge badge-light mt-1">{{timeseries[parameter.parameterSymbol].length}}</span></b-btn>
          </template>
          <b-collapse :id="parameter.listName" v-bind:style="{ display: isCollapsed(parameter) ? 'block' : 'none'}" :class="isCollapsed(parameter) ? 'collapse show' : null" class="mt-2" accordion="my-accordion">
             <table id="timeseriesTable" class="table table-striped" :keyword="keyword">
              <tbody>

              <tr class="tableGroupHeadingRow" v-if="showHeaders(parameter)"><td></td><td><h4 class="tableGroupHeading">{{ $t('analysis.your_series') }}</h4></td><td></td><td></td><td></td></tr>
              <tr class="device-row" v-for="series in ownedSeries(parameter)" :key="series.id">
                  <td v-if="series.data_source === 'FixeauPin'"><span class="triangle"></span></td>
                  <td v-if="series.data_source === 'FixeauMobile'"><span class="dot"></span></td>
                  <td v-if="series.data_source === 'Other'"><span class="square"></span></td>
                <td colspan="3">{{series.name}}</td>
                <td >
                  <label class="form-checkbox custom-control float-right">
                    <input type="checkbox" :value="series.id" v-model.lazy="selected" :disabled="ignoreSelectorChange">
                    <i class="form-icon"></i>
                  </label>
                </td>
              </tr>

              <tr class="tableGroupHeadingRow" v-if="showHeaders(parameter)"><td></td><td><h4 class="tableGroupHeading">{{ $t('analysis.other_series') }}</h4></td><td></td><td></td><td></td></tr>
              <tr class="device-row" v-for="series in otherSeries(parameter)" :key="series.id">

                <td v-if="series.data_source === 'FixeauPin'"><span class="triangle"></span></td>
                <td v-if="series.data_source === 'FixeauMobile'"><span class="dot"></span></td>
                <td v-if="series.data_source === 'Other'"><span class="square"></span></td>
                <td colspan="3">{{series.name}}</td>
                <td>
                  <label class="form-checkbox custom-control float-right">
                    <input type="checkbox" :value="series.id" v-model.lazy="selected" :disabled="ignoreSelectorChange">
                    <i class="form-icon"></i>
                  </label>
                </td>
              </tr>
              <tr v-if="showShowAll(parameter)">
                <td colspan="5">
                  <button class="btn btn-sm float-right" @click="showAll=true">{{ $t('analysis.show_all_series') }}</button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-collapse>
        </b-form-group>
      </div>
      <div class="skeleton" v-else>
        <skeleton-box width="100%" height="46px" />
        <skeleton-box width="100%" height="46px" />
        <skeleton-box width="100%" height="46px" />
        <skeleton-box width="100%" height="46px" />
      </div>
    </div>
  </aside>
</template>

<script>
  import analysisAction from './analysisAction.vue';
  import axios from 'axios';
  import i18n from '@/i18n';
  import JQUERY from 'jquery';
  import SkeletonBox from '../skeleton-loading/skeleton-box';
  export default {
    props: ['selectedTimeseries'],
    name: "analysis-selector",
    components: {
      SkeletonBox,
      analysisAction
    },
    data() {
      return {
        isNameSorted: true,
        sortingName: i18n.t('analysis.sorting_name'),
        keyword: '',  // keyword that was searched on
        parameters: [
          {parameterSymbol: "EC", name: i18n.t('parameters.ec'), listName: "EC"},
          {parameterSymbol: "T", name: i18n.t('parameters.temperature'), listName: "T"},
          {parameterSymbol: "WaterLevel", name: i18n.t('parameters.water_level'), listName: "WaterLevel"},
          {parameterSymbol: "WaterLevelNAP", name: i18n.t('parameters.water_level'), listName: "WaterLevel"},
          {parameterSymbol: "pH", name: i18n.t('parameters.ph'), listName: "pH"},
          {parameterSymbol: "SoilMoisture", name: i18n.t('parameters.soil_moisture'), listName: "SoilMoisture"},
          {parameterSymbol: "NO3", name: i18n.t('parameters.no3'), listName: "NO3"},
          {parameterSymbol: "O2", name: i18n.t('parameters.oxygen'), listName: "O2"},
          {parameterSymbol: "Precipitation", name: i18n.t('parameters.precipitation'), listName: "Precipitation"},
          {parameterSymbol: 'SolarRadiation', name: i18n.t('parameters.radiation'), listName: "SolarRadiation"},
          {parameterSymbol: 'WindSpeed', name: i18n.t('parameters.wind_speed'), listName: "WindSpeed"}
          // {parameterSymbol: 'WindDirection', name: 'Windrichting'}
        ],
        selectedParameter: null, //Parameter which is active in the accordeon
        selected: [],  // selected TimeSeries ids
        allSeriesIds: [],  // all TimeSeries ids
        dismissCountDown: 0,  // Combined with dismissSecs
        dismissSecs: 8,  // Display >4 selected error for 8 secs
        selectedTimeseriesLocal: null,  // selected TimeSeries objects
        watchingSelectorChange: false,  // it is used to wait until already selected TimeSeries are added to the chart, otherwise they are added twice.
        ignoreSelectorChange: false,  // same as above
        allowToggleSurfaceLevel: true,
        hasOwned: {},
        hasOther: {},
        hasMany: {},
        showAll: false
      }
    },
    created() {
      let vm =this;
      let selectedTimeseries;
      if (vm.selectedTimeseries !== undefined) {
        selectedTimeseries = vm.selectedTimeseries
      } else if (vm.$store.getters.itemsBasket.length > 0) {
        let array = []
        vm.$store.getters.itemsBasket.forEach(serie => array.push({series: serie, parameter: localStorage.getItem('selectedInTheSelector')}))
        selectedTimeseries = array;
      } else {
        selectedTimeseries = undefined;
      }
      this.selectedTimeseriesLocal = selectedTimeseries;
    },
    mounted () {
      let vm =this;
      if (vm.selectedTimeseriesLocal !== undefined) {
        vm.selectedTimeseriesLocal.forEach(timeseries => {
          axios.get('/series/' + timeseries.series).then(response => {
            this.$parent.$refs.chart_component.load(response.data);
          })
        })
      }
      NProgress.start()
      this.$store.dispatch('retrieveTimeseries').then(() => {
        if (vm.selectedTimeseriesLocal !== undefined) {
          let selectedTimeseries = vm.selectedTimeseriesLocal;
          let elementId;
          if (selectedTimeseries[0]) {
            if (selectedTimeseries[0].parameter) {
              elementId = `#${selectedTimeseries[0].parameter}`;
            } else {
              let names = {
                1: i18n.t('parameters.battery'),
                2: i18n.t('parameters.ec'),
                3: i18n.t('analysis.ground_water_level'),
                5: i18n.t('parameters.soil_moisture')
              }
              elementId = `#${names[selectedTimeseries[0].type]}`;
            }
          }
          const btnId = `${elementId}A`;
          JQUERY(btnId).trigger('click');
          let selectedSeries = [];
          selectedTimeseries.forEach(serie => selectedSeries.push(serie.series))
          vm.selected = selectedSeries
        }
        vm.watchingSelectorChange = true;
        vm.$watch('selected', function(newVal, oldVal) {
          if (newVal.length > 4) {
            this.selected = oldVal;
            this.dismissCountDown = this.dismissSecs;
            this.ignoreSelectorChange = true;
            const vm =this;
            setTimeout(function() {vm.ignoreSelectorChange = false},500)
          } else if (!this.ignoreSelectorChange){

            localStorage.setItem('selectedTimeseriesBorden', newVal)
            let id = newVal.slice(-1)[0];
            // var isDuplicate = newVal.filter(item => item == id).length;

            if (id !== undefined && newVal.length > oldVal.length){
              let differences = _.differenceWith(newVal, oldVal, _.isEqual)
              let param = this.selectedParameter;
              if (this.selectedParameter == 'WaterLevel' && !this.$store.state.preferSurfaceLevel) {
                param = this.selectedParameter+"NAP";
              }

              differences.forEach(id => {
                const series = this.timeseries[param].features.find(series => series.id == id);
                this.$parent.$refs.chart_component.load(series);
              })
              setTimeout(function() {vm.ignoreSelectorChange = false},500)
            } else if (oldVal.length > newVal.length) {
              let uncheckedId = oldVal.filter(function(n){ return newVal.indexOf(n)>-1?false:n;});


              let chart = this.$parent.$refs.chart_component;
              //Set a delay to prevent double click (serie cannot be removed if not loaded yet..)
              setTimeout(function(){chart.removeSeries(uncheckedId[0]); },  450);
            }
          }
        })
      }).then(() => {
        NProgress.done()
      })
    },
    computed: {
      timeseries() {
        let timeseries = Object.assign([], this.$store.state.timeseries);

        if (this.keyword) {
          let returnList = [];

          for ( var key in timeseries ) {
            var serie = timeseries[key];
            if (key == 'WaterLevel' && !this.$store.state.preferSurfaceLevel) {
               serie = timeseries[key+"NAP"];
            }

            returnList[key] = {};
            returnList[key]['type'] = 'FeatureCollection';
            returnList[key].features =  serie.features.filter(item => item.name.toLowerCase().includes(this.keyword.toLowerCase()));
          }
          return returnList;
        }
        return timeseries;
      },
      shownParameters() {
        return this.parameters.filter(parameter => 
          this.timeseries[parameter.parameterSymbol] && this.timeseries[parameter.parameterSymbol].features.length && this.toggleNAPGroup(parameter)
        )
      },
      prefersSurfaceLevel() {
        return this.$store.state.preferSurfaceLevel;
      },
      hasPrecipitation() {
        return (this.$parent.$refs.chart_component.idForPrecipitation !== null) ? true : false;
      },
      isSimple() {
        return this.$store.state.isSimple;
      },
      isWaterLevel() {
        return this.selectedParameter == 'WaterLevel' || this.selectedParameter == 'WaterLevelNAP';
      },
      isAdvancedWaterLevel() {
        return this.isWaterLevel && !this.isSimple;
      }
    },
    methods: {
      sort() {
        if (this.isNameSorted) {
          this.isNameSorted = false;
          this.sortingName = i18n.t('analysis.sorting_time');
        } else {
          this.isNameSorted = true;
          this.sortingName = i18n.t('analysis.sorting_name');
        }
      },
      sortByName(series) {
        return series.sort((t1,t2) => {
          if (!t1.name) {
            return 1;
          }
          if (!t2.name) {
            return -1;
          }
          return t1.name < t2.name ? -1 : 1;
        })
      },
      sortByTime(series) {
        return series.sort((t1,t2) => t1.latest.time > t2.latest.time ? -1 : 1)
      },
      sorted(series) {
        return this.isNameSorted ? this.sortByName(series) : this.sortByTime(series);
      },
      parameterSeries(parameter) {
        return this.timeseries[parameter.parameterSymbol].features;
      },
      ownedSeries(parameter) {
        let series = this.parameterSeries(parameter).filter(s => s.owned);
        this.hasOwned[parameter.name] = series.length > 0;
        return this.sorted(series);
      },
      otherSeries(parameter) {
        let series = this.parameterSeries(parameter).filter(s => !s.owned);
        this.hasOther[parameter.name] = series.length > 0;
        let sortedSeries = this.sorted(series);
        this.hasMany[parameter.name] = sortedSeries.length > 100;
        if (!this.showAll) {
          sortedSeries = sortedSeries.slice(0, 100);
        }
        return sortedSeries
      },
      showHeaders(parameter) {
        return this.hasOwned[parameter.name] && this.hasOther[parameter.name];
      },
      showShowAll(parameter) {
        return !this.showAll && this.hasMany[parameter.name];
      },
      loadSeries (parameter) {

        localStorage.setItem('selectedParameterTimeseriesBorden', parameter)
        let vm = this;

        //Set selectedParameter to NULL when closing the accordeon.
        if(this.selectedParameter == parameter){
          this.selectedParameter = null;
        } else {
          this.selectedParameter = parameter;
        }

        let param =  this.selectedParameter;
        if (this.selectedParameter == 'WaterLevel' && !this.$store.state.preferSurfaceLevel) {
          param = this.selectedParameter+"NAP";
        }


        this.ignoreSelectorChange = true;
        setTimeout(function(){vm.ignoreSelectorChange = false},1000);
        this.selected = [];
        if(this.watchingSelectorChange) {
          this.$parent.$refs.chart_component.removeAllSeries();
        }


        if (this.timeseries && this.selectedParameter != null){
          let allSeries = [];
          this.timeseries[param].features.forEach(series => allSeries.push(series.id))
          this.allSeriesIds = allSeries;
          return allSeries
        }
      },
      togglePrecipitation() {
        this.$parent.$refs.chart_component.togglePrecipitation();
      },
      toggleNAPGroup(parameter) {

        if(parameter.listName == 'WaterLevel') {
          if (parameter.parameterSymbol == 'WaterLevelNAP' && this.prefersSurfaceLevel) {
            return false;
          } else if(parameter.parameterSymbol == 'WaterLevel' && !this.prefersSurfaceLevel) {
            return false;
          }
        }
        return true;
      },

      toggleSurfaceLevel() {
        //Temporarily disable toggleSurfaceLevel button to avoid multiple requests:
        if (!this.allowToggleSurfaceLevel) return;
        this.allowToggleSurfaceLevel = false;

        this.$store.commit("preferSurfaceLevel",  !this.$store.state.preferSurfaceLevel );

        //Reload timeseries
        this.$store.dispatch('retrieveTimeseries', this.selectedParameter).then(() => {
          //Reload the chart
          this.$parent.$refs.chart_component.reloadChart();
          this.allowToggleSurfaceLevel=true;
        });
      },
      isCollapsed(parameter) {
        if(this.selectedParameter == parameter.listName) {
          return true;
        }
        return false;
      }
    }
  }
</script>

<style scoped>
  .analysis-container {
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    margin-bottom: 60px;
  }
  .mt-2 {
    margin-top: 0 !important;
  }
  .mt-2 p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
    text-transform: none;
    margin: 0;
    overflow: hidden;
    max-width: calc(100% - 30px);
  }
  .device-row {
    padding: 0px 0px;
    font-size: 14px;
  }
  .device-row td {
    padding: 4px;
    margin: 0;
    padding-left: 15px;
  }
  .device-row td .form-checkbox{
    padding: 0;
    margin: 0;
    width: 26px;
  }
  .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #538dcf;
  }
  .tableGroupHeadingRow {
    background-color: #888888 !important;
    padding: 0 ;
    margin: 0;
  }
  .tableGroupHeadingRow td {
    padding: 0 ;
    margin: 0;
  }
  .tableGroupHeading {
    color: white;
    padding: 0px 0px !important;
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  #search_and_sort_div {
    margin: 8px;
    font-size: 14px;
  }
  #search_and_sort_div div {
    padding: 0;
  }
  #searchBox {
    margin-top: 1px;
    height: 28px;
    float: right;
  }
  #sorting_button {
    margin-top: 1px;
    padding: 4px;
    font-size: 12px;
    color: white;
    background-color: #969696;
    max-width: 100px;
    float: right !important;
  }
  .btn-secondary,
  .btn-secondary:hover, .btn-secondary.hover,
  .btn-secondary:focus, .btn-secondary.focus,
  .btn-secondary:active, .btn-secondary.active {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 15px;
    line-height: 27px;
    color: #4a4a4a !important;
    text-decoration: none;
    border-radius: 0;
    background-color: #ffffff !important;
    padding: 10px 5px 10px 30px;
    border: none !important;
    border-top: 1px solid #F2EFE9 !important;
    border-bottom: 1px solid #F2EFE9 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-image: url(../../assets/images/arrow-down-green.png);
    background-position: 3px 12px;
    background-repeat: no-repeat;
  }
  .btn-secondary.collapsed {
    border-bottom: none !important;
    border-top: 1px solid #F2EFE9 !important;
    background-image: url(../../assets/images/arrow-right-green.png);
  }
  .custom-control {
    height: auto;
    padding-right: 0;
    padding-top: 2px;
    padding-bottom: 10px;
    border-radius: 0;
  }
  .badge {
    color: #4a4a4a;
    background-color: #ffffff !important;
  }
  .alert-danger {
    margin: 0px !important;
    background-color: #ff2146 !important;
    color: white;
    border: 0px !important;
    border-radius: 0px !important;
  }
  .skeleton > span + span {
    border-top: 1px solid #F2EFE9;
  }
  #toggle_precipitation, #toggle_prefers_surface_level {
    margin-left: 25px;
    margin-bottom: 3px;
    margin-top: 5px;
    background-color:transparent;
  }
  #analysis_action {
    margin-left: 25px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  #toggle_precipitation:hover, #toggle_prefers_surface_level:hover {
    border: 1px solid black !important;
  }

  #toggle_precipitation:focus, #toggle_prefers_surface_level:focus {
    outline: none ;
    -webkit-box-shadow: none;
    box-shadow: none ;

  }

  #toggle_prefers_surface_level {
    background-image:url(../../assets/images/nap.png);
    background-size:45px 45px;
    background-repeat: no-repeat;
    background-position: center;
    height:50px;
    width:50px;
  }

  #toggle_prefers_surface_level.active {
    background-image: url(../../assets/images/nap-active.png);
  }

  .active {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }

  .dot {
      height: 12px;
      width: 12px;
      background-color: #bbb ;
      border-radius: 50%;
      display: inline-block;
  }

  .square {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    display: inline-block;
  }

  .triangle {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #bbb ;
      display: inline-block;
  }

</style>
